import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import "./SetupPageComponent.scss";
import {ValuePickerComponent} from "../component/ValuePickerComponent";
import {Random} from "../entity/Random";

interface SetupPageComponentProps {
    readonly categories: string[];
    readonly onConfirm: (categories: string[], basic?: string, medium?: string, expert?: string, random?: Random) => void;
}

export function SetupPageComponent(props: SetupPageComponentProps): React.ReactElement {
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);
    const [selectBasic, setSelectBasic] = React.useState<string | undefined>(undefined);
    const [selectMedium, setSelectMedium] = React.useState<string | undefined>(undefined);
    const [selectExpert, setSelectExpert] = React.useState<string | undefined>(undefined);
    const [selectRandom, setSelectRandom] = React.useState<Random | undefined>(undefined);

    function handleButtonClick(category: string) {
        if (selectedCategories.includes(category)) {
            setSelectedCategories([...selectedCategories.filter((x) => x !== category)]);
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    }


    function renderButton(category: string): React.ReactElement {
        return <Button
            key={category}
            variant={"outline-primary"}
            onClick={() => handleButtonClick(category)}
            className={selectedCategories.includes(category) ? "buttonCategory active" : "buttonCategory"}
        >{category}</Button>;
    }

    function handleRandomClick(value: string): void {
        setSelectRandom(value as Random);
        setSelectBasic(undefined);
        setSelectMedium(undefined);
        setSelectExpert(undefined);
    }

    function handleBasicClick(value: string): void {
        setSelectRandom(undefined);
        setSelectBasic(value);
    }

    function handleMediumClick(value: string): void {
        setSelectRandom(undefined);
        setSelectMedium(value);
    }

    function handleExpertClick(value: string): void {
        setSelectRandom(undefined);
        setSelectExpert(value);
    }

    function handleNextClick(): void {
        props.onConfirm(selectedCategories, selectBasic, selectMedium, selectExpert, selectRandom);
    }

    function disableButton(): boolean {
        if (selectedCategories.length === 0) {
            return true;
        }
        if (selectBasic === "0" && selectMedium === "0" && selectExpert === "0") {
            return true;
        }
        if (selectRandom) {
            return false;
        }
        return !selectBasic || !selectMedium || !selectExpert;
    }

    return <Container className={"SetupPageComponent"}>
        <Row className={"parchment setupPage"}>
            <Col>{props.categories.map(renderButton)}</Col>
            <Col>
                <div className={"questionsValue"}>
                    <div className={"advancementName"}>BASIC</div>
                    <ValuePickerComponent
                        value={selectBasic}
                        id={"basic"}
                        values={["0", "3", "5", "10"]}
                        onChange={handleBasicClick}
                    />
                </div>
                <div className={"questionsValue"}>
                    <div className={"advancementName"}>MEDIUM</div>
                    <ValuePickerComponent
                        value={selectMedium}
                        id={"medium"}
                        values={["0", "3", "5", "10"]}
                        onChange={handleMediumClick}
                    />
                </div>
                <div className={"questionsValue"}>
                    <div className={"advancementName"}>EXPERT</div>
                    <ValuePickerComponent
                        value={selectExpert}
                        id={"expert"}
                        values={["0", "3", "5", "10"]}
                        onChange={handleExpertClick}
                    />
                </div>
                <div className={"questionsValue"}>
                    <div className={"advancementName"}>RANDOM</div>
                    <ValuePickerComponent
                        value={selectRandom}
                        id={"random"}
                        values={["SHORT", "AVERAGE", "LONG"]}
                        onChange={handleRandomClick}/>
                </div>
            </Col>
            <Button variant="primary" className={"buttonNext"} onClick={handleNextClick}
                    disabled={disableButton()}>Dalej</Button>
        </Row>
    </Container>;
}