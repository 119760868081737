import React from "react";
import {AnswerComponent} from "./AnswerComponent";
import {Question} from "../entity/Question";
import "./QuestionComponent.scss";
import _ from "lodash";
import {Answer} from "../entity/Answer";
import {Button} from "react-bootstrap";

interface QuestionComponentProps {
    readonly question: Question;
    readonly onNext: (correct: boolean) => void;
}

export function QuestionComponent(props: QuestionComponentProps): React.ReactElement {
    const [userAnswer, setUserAnswer] = React.useState <number>(-1);
    const [confirmed, setConfirmed] = React.useState<boolean>(false);

    const answers: Answer[] = React.useMemo(() => _.shuffle(props.question.answers), [props.question]);

    function renderExplanation() {
        if (!confirmed) {
            return <></>;
        }
        const answer = answers[userAnswer];
        return <div className={"explanation"}>{answer.explanation}</div>;
    }

    function handleNextClick() {
        props.onNext(answers[userAnswer].correct);
        setUserAnswer(-1);
        setConfirmed(false);
    }

    function handleAnswerClick(index: number) {
        if (!confirmed) {
            setUserAnswer(index);
        }
    }

    function renderAnswerComponent(answer: Answer, index: number) {
        return <AnswerComponent
            key={answer.text}
            revealed={confirmed}
            selected={index === userAnswer}
            onClick={() => handleAnswerClick(index)}
            answer={answer}/>;
    }

    return <div className={"QuestionComponent"}>
        <div className={"parchment"}>{props.question.text}<br/><pre>{props.question.code}</pre></div>
        {answers.map(renderAnswerComponent)}
        {renderExplanation()}
        {!confirmed &&
            <Button
                onClick={() => setConfirmed(true)}
                variant="primary"
                disabled={userAnswer === -1}
                className={"button-next"}
            >Potwierdź</Button>}

        {confirmed && <Button onClick={handleNextClick} className={"button-next"} variant="primary">Następne</Button>}
    </div>;
}
