import React from "react";
import "./App.scss";
import {Question} from "./entity/Question";
import axios from "axios";
import {SetupPageComponent} from "./page/SetupPageComponent";
import _ from "lodash";
import {QuestionComponent} from "./component/QuestionComponent";
import {ResultComponent} from "./page/ResultComponent";
import {Random} from "./entity/Random";

export function App() {
    type Stage = "Setup" | "Game" | "Result";

    const [questions, setQuestions] = React.useState<Question[]>([]);
    const [selectedQuestions, setSelectedQuestions] = React.useState<Question[]>([]);
    const [stage, setStage] = React.useState<Stage>("Setup");
    const [indexQuestion, setIndexQuestion] = React.useState<number>(0);
    const [basicCorrect, setBasicCorrect] = React.useState<number>(0);
    const [mediumCorrect, setMediumCorrect] = React.useState<number>(0);
    const [expertCorrect, setExpertCorrect] = React.useState<number>(0);

    React.useEffect(() => {
        getData();
    }, []);

    function getData() {
        axios.get("https://public.andret.eu/questions.json")
            .then(x => x.data)
            .then(x => setQuestions(x));
    }

    function handleNext(correct: boolean) {
        if (correct) {
            switch (selectedQuestions[indexQuestion].advancement) {
                case "basic":
                    setBasicCorrect((prevState) => prevState + 1);
                    break;
                case "medium":
                    setMediumCorrect((prevState) => prevState + 1);
                    break;
                case "expert":
                    setExpertCorrect((prevState) => prevState + 1);
                    break;
            }
        }
        if (selectedQuestions.length - 1 === indexQuestion) {
            setStage("Result");
        } else {
            setIndexQuestion((prevState) => prevState + 1);
        }
    }

    function handleSetupConfirm(categories: string[], basic?: string, medium?: string, expert?: string, random?: Random) {
        setStage("Game");
        const shuffled = _.shuffle(questions.filter((x) => categories.includes(x.category)));
        if (random) {
            switch (random) {
                case "SHORT":
                    setSelectedQuestions(shuffled.slice(0, 6));
                    break;
                case "AVERAGE":
                    setSelectedQuestions(shuffled.slice(0, 10));
                    break;
                case "LONG":
                    setSelectedQuestions(shuffled.slice(0, 16));
                    break;
            }
        } else {
            const selected = _.shuffle([
                ...shuffled.filter((x) => x.advancement === "basic").slice(0, +(basic ?? "0")),
                ...shuffled.filter((x) => x.advancement === "medium").slice(0, +(medium ?? "0")),
                ...shuffled.filter((x) => x.advancement === "expert").slice(0, +(expert ?? "0"))]);
            setSelectedQuestions(selected);
        }
    }

    switch (stage) {
        case "Setup": {
            return <SetupPageComponent categories={_.uniq(questions.map((x) => x.category))}
                                       onConfirm={handleSetupConfirm}></SetupPageComponent>;
        }
        case "Game": {
            return <QuestionComponent onNext={handleNext} question={selectedQuestions[indexQuestion]}/>;
        }
        case "Result": {
            return <ResultComponent
                basicCorrect={basicCorrect}
                basicAll={selectedQuestions.filter(question => question.advancement === "basic").length}
                mediumCorrect={mediumCorrect}
                mediumAll={selectedQuestions.filter(question => question.advancement === "medium").length}
                expertCorrect={expertCorrect}
                expertAll={selectedQuestions.filter(question => question.advancement === "expert").length}
            />;
        }
    }
}
