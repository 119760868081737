import React from "react";
import {Answer} from "../entity/Answer";
import "./AnswerComponent.scss";

interface AnswerComponentProps {
    readonly answer: Answer;
    readonly onClick: () => void;
    readonly selected: boolean;
    readonly revealed: boolean;
}

export function AnswerComponent(props: AnswerComponentProps): React.ReactElement {

    return <div onClick={props.onClick} className={"AnswerComponent"}>
        <div className={"answer " + (props.selected ? "active" : "") + (props.revealed && props.answer.correct ? " correct" : "")}>{props.answer.text}</div>
    </div>;
}