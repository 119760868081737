import React from "react";
import {Col, Row} from "react-bootstrap";
import ".//ResultComponent.scss";

interface ResultComponentProps {
    readonly basicCorrect: number;
    readonly basicAll: number;
    readonly mediumCorrect: number;
    readonly mediumAll: number;
    readonly expertCorrect: number;
    readonly expertAll: number;
}

export function ResultComponent(props: ResultComponentProps): React.ReactElement {

    const isPassed: boolean = points() / allPoints() > 0.6;

    function points(): number {
        return props.basicCorrect + props.mediumCorrect * 2 + props.expertCorrect * 3;
    }

    function allPoints(): number {
        return props.basicAll + props.mediumAll * 2 + props.expertAll * 3;
    }

    return <div className={"ResultComponent"}>
        <div className={"parchment"}>
            <Row>
                <Col className={"correct"}>
                    <p>Poprawne odpowiedzi:</p>
                    <p>Basic: {props.basicCorrect}/{props.basicAll}</p>
                    <p>Medium: {props.mediumCorrect}/{props.mediumAll}</p>
                    <p>Expert: {props.expertCorrect}/{props.expertAll}</p>
                </Col>
                <Col className={"procent"}>
                    <p>Twój wynik:</p>
                    <p>{points()}/{allPoints()}</p>
                    <p>{(points() / allPoints() * 100).toFixed(2)}%</p>
                </Col>
            </Row>
        </div>
        <div><p
            className={"result " + (isPassed ? "plus" : "minus")}>{isPassed ? "Zdałeś! :)" : "Nie udało Ci się zdać :("}</p>
        </div>
    </div>;
}
