import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import React from "react";
import "./ValuePickerComponent.scss"

interface ValuePickerComponentProps {
    values: string[];
    id: string;
    onChange: (value: string) => void;
    value: string | undefined;
}

export function ValuePickerComponent(props: ValuePickerComponentProps): React.ReactElement {

    function renderToggleButton(value: string, index: number): React.ReactElement {
        return <ToggleButton
            className={props.value === value ? "active" : "buttonValue"}
            key={value}
            onChange={() => props.onChange(value)}
            id={props.id + index}
            value={value}
        >{value}</ToggleButton>;
    }

    return <ToggleButtonGroup className={"ValuePickerComponent"} value={props.value || []} type="radio" name={props.id} defaultValue={1}>
        {props.values.map(renderToggleButton)}
    </ToggleButtonGroup>;
}